import { Dispatch, SetStateAction, SyntheticEvent } from "react";
import {
  CreateLocationOptions,
  Location,
  RemovedFile,
} from "../../../../../types";
import { getFileRef } from "../../../../../firebase/actions";

type FileChange = {
  e: SyntheticEvent;
  setPreviewUrls: Dispatch<SetStateAction<string[]>>;
  For: "main_menu" | "banners" | "reward";
  setSelectedOptions: Dispatch<SetStateAction<CreateLocationOptions>>;
  mode?: "UPDATE" | "CREATE" | "DELETE";
  sourceData?: Location;
  setRemovedFiles: Dispatch<SetStateAction<RemovedFile[]>>;
  index?: number;
};

type FileDeleletion = Omit<FileChange, "e"> & {
  previewUrls: string[];
  idx: number;
  selectedOptions: CreateLocationOptions;
};

type FileUpdate = {
  previewUrls: string[];
  idx: number;
  index?: number;
  mode?: "UPDATE" | "CREATE" | "DELETE";
  For: "main_menu" | "banners" | "reward";
  sourceData?: Location;
  selectedOptions: CreateLocationOptions;
  setRemovedFiles: Dispatch<SetStateAction<RemovedFile[]>>;
};
export const handleFileChange = async ({
  e,
  setPreviewUrls,
  For,
  setSelectedOptions,
  mode,
  sourceData,
  setRemovedFiles,
  index,
}: FileChange) => {
  const target = e.target as HTMLInputElement;

  if (!target.files || target.files.length === 0) {
    return;
  }

  const filesArray = Array.from(target.files);

  if (For === "main_menu" || For === "reward") {
    const file = filesArray[0];
    const url = URL.createObjectURL(file);
    setPreviewUrls([url]);

    if (mode === "UPDATE" && sourceData) {
      if (For === "main_menu") {
        const firebaseUrl = sourceData.picture;
        const fileRef = getFileRef(sourceData, firebaseUrl, For);
        const urlArray = fileRef.fullPath.split("/");
        const fileName = urlArray[urlArray.length - 1];
        setRemovedFiles((prev) => [
          ...prev,
          {
            removedFile: {
              deleteFrom: fileRef,
              name: fileName,
              from: For,
            },
          },
        ]);
      } else if (For === "reward" && index !== undefined) {
        if (index < sourceData.rewards.length) {
          const firebaseUrl = sourceData.rewards[index].rewardPicture;
          const fileRef = getFileRef(sourceData, firebaseUrl, For);
          const urlArray = fileRef.fullPath.split("/");
          const fileName = urlArray[urlArray.length - 1];
          setRemovedFiles((prev) => [
            ...prev,
            {
              removedFile: {
                deleteFrom: fileRef,
                name: fileName,
                index: index,
                from: For,
              },
            },
          ]);
        }
      }
    }
  } else if (For === "banners") {
    const urls = await Promise.all(
      filesArray.map((file) => URL.createObjectURL(file)),
    );
    setPreviewUrls((prevUrls) => [...prevUrls, ...urls]);
  }

  setSelectedOptions((prevOptions) => {
    const auxFiles = { ...prevOptions.files };

    switch (For) {
      case "main_menu":
        auxFiles.main_menu = filesArray[0];
        break;

      case "banners":
        const bannerIndex = auxFiles.banners.length;
        if (auxFiles.banners[bannerIndex] === undefined && index !== undefined)
          auxFiles.banners[bannerIndex] = { atIndex: index, images: [] };
        auxFiles.banners[bannerIndex].images = filesArray;
        break;

      case "reward":
        if (index !== undefined)
          auxFiles.rewards = [
            ...auxFiles.rewards,
            { atIndex: index, image: filesArray[0] },
          ];
        break;

      default:
        break;
    }

    return { ...prevOptions, files: auxFiles };
  });
};

const updateFiles = ({
  selectedOptions,
  For,
  idx,
  mode,
  sourceData,
  index,
  previewUrls,
  setRemovedFiles,
}: FileUpdate) => {
  const auxFiles = selectedOptions.files;

  switch (For) {
    case "main_menu":
      if (mode === "CREATE") {
        auxFiles.main_menu = null;
      } else if (mode === "UPDATE" && sourceData) {
        const url = previewUrls[0];
        const fileRef = getFileRef(sourceData, url, For);
        const urlArray = fileRef.fullPath.split("/");
        const fileName = urlArray[urlArray.length - 1];

        setRemovedFiles((prev) => [
          ...prev,
          {
            removedFile: {
              deleteFrom: fileRef,
              name: fileName,
              from: For,
            },
          },
        ]);
      }
      break;

    case "banners":
      if (index !== undefined) {
        if (mode === "CREATE") {
          auxFiles.banners[index].images = auxFiles.banners[
            index
          ].images.filter((_, i) => i !== idx);
        } else if (mode === "UPDATE" && sourceData) {
          const previewImagesLength =
            sourceData.multiLocations[index].pictures.length;

          if (idx >= previewImagesLength) {
            const newFileIndex = idx - previewImagesLength;
            auxFiles.banners[index].images = auxFiles.banners[
              index
            ].images.filter((_, i) => i !== newFileIndex);
          } else {
            const url = previewUrls[idx];
            const fileRef = getFileRef(sourceData, url, For);
            const urlArray = fileRef.fullPath.split("/");
            const fileName = urlArray[urlArray.length - 1];

            setRemovedFiles((prev) => [
              ...prev,
              {
                removedFile: {
                  deleteFrom: fileRef,
                  name: fileName,
                  index: index,
                  from: For,
                },
              },
            ]);
          }
        }
      }
      break;

    case "reward":
      if (mode === "CREATE") auxFiles.rewards.splice(idx, 1);
      else if (mode === "UPDATE" && sourceData && index !== undefined) {
        const url = previewUrls[0];
        const fileRef = getFileRef(sourceData, url, For);
        const urlArray = fileRef.fullPath.split("/");
        const fileName = urlArray[urlArray.length - 1];

        setRemovedFiles((prev) => [
          ...prev,
          {
            removedFile: {
              deleteFrom: fileRef,
              name: fileName,
              index: index,
              from: For,
            },
          },
        ]);
      }
      break;

    default:
      break;
  }

  return { ...selectedOptions, files: auxFiles };
};

export const handleDeleteFile = ({
  setPreviewUrls,
  For,
  selectedOptions,
  setSelectedOptions,
  previewUrls,
  idx,
  mode,
  sourceData,
  setRemovedFiles,
  index,
}: FileDeleletion) => {
  const auxPreviewUrls = [...previewUrls];
  auxPreviewUrls.splice(idx, 1);
  setPreviewUrls(auxPreviewUrls);

  const options = updateFiles({
    selectedOptions,
    For,
    idx,
    mode,
    sourceData,
    index,
    previewUrls,
    setRemovedFiles,
  });

  setSelectedOptions(options);
};

export const handleNumberInput = (e: KeyboardEvent): void => {
  const isNumber = /[0-9]/.test(e.key);
  const isAllowedKey = ["Backspace", "Control", "Meta"].includes(e.key);
  const isCopyPaste = (e.ctrlKey || e.metaKey) && ["v", "c"].includes(e.key);

  if (!isNumber && !isAllowedKey && !isCopyPaste) {
    e.preventDefault();
  }
};