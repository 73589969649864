import {
  CreateLocationOptions,
  ExtendedUser,
  Location,
  LocationUser,
  MultiLocation,
  RemovedFile,
  ToastOptions,
} from "../../../../types";
import { QueryClient, useQueryClient } from "@tanstack/react-query";
import {
  collection,
  deleteDoc,
  doc,
  GeoPoint,
  getFirestore,
  setDoc,
  Timestamp,
  updateDoc,
} from "firebase/firestore";
import {
  deleteObject,
  getDownloadURL,
  getStorage,
  listAll,
  ref,
  StorageReference,
  uploadBytes,
} from "firebase/storage";
import { auth, storage } from "../../../../firebase/firebaseConfig";
import { geocode, RequestType } from "react-geocode";
import _ from "lodash";
import { Dispatch, SetStateAction } from "react";
import { getDocRef, getFileRef } from "../../../../firebase/actions";
import { toast } from "react-toastify";
import imageCompression from "browser-image-compression";
import { createUserWithEmailAndPassword } from "firebase/auth";

export const toastOptions = ({ status, position, message }: ToastOptions) => {
  const options = {
    position: position,
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: false,
    progress: undefined,
    theme: "dark",
  };
  switch (status) {
    case "success":
      toast.success(message, options);
      break;
    case "error":
      toast.error(message, options);
      break;
  }
};

const db = getFirestore();
export const createLocation = async (
  location: Location,
  locationName: string,
  queryClient: ReturnType<typeof useQueryClient>,
  user: Partial<LocationUser>,
) => {
  if (user.email === undefined || user.password === undefined) return;
  try {
    const userCredentials = await createUserWithEmailAndPassword(
      auth,
      user.email,
      user.password,
    );
    const authUser = userCredentials.user;

    const userCollection = collection(db, "users");
    const userRef = doc(userCollection, authUser.uid);

    const locationsColRef = collection(db, "locations");
    const docRef = doc(locationsColRef, locationName);

    await setDoc(docRef, {
      ...location,
      docRef: docRef,
      dateAdded: Timestamp.now(),
      scannedQRs: 0,
      adminDocRef: userRef,
    });

    await setDoc(userRef, {
      ...user,
      created_time: Timestamp.now(),
      newUser: true,
      new_points: false,
      new_points_location: "",
      new_points_points: 0,
      type: "location",
      uid: authUser.uid,
      restaurant_id: docRef,
    });

    if (process.env.NODE_ENV === "development")
      console.log("location created with id: ", docRef.id);
    await queryClient.invalidateQueries({ queryKey: ["locations"] });
  } catch (error) {
    if (process.env.NODE_ENV === "development")
      console.error("Error creating location: ", error);
  }
};

export const onCreate = async (
  data: Location,
  selectedOptions: CreateLocationOptions,
  queryClient: ReturnType<typeof useQueryClient>,
  user: Partial<LocationUser>,
) => {
  const { mainTag, additionalTag, files, cities } = selectedOptions;
  let tags: string[];
  let citiesArray: string[];

  if (mainTag === "" || additionalTag === "") tags = [mainTag, additionalTag];
  else tags = [mainTag, additionalTag].filter(Boolean);

  if (cities.length === 0) citiesArray = [];
  else citiesArray = [...new Set(cities)];

  const { main_menu, banners, rewards } = files;
  const locationName = data.name.replace(/[^a-zA-Z0-9]/g, "_");

  if (main_menu && banners.length > 0 && rewards.length > 0) {
    const mainMenuRef = ref(
      storage,
      `location_pictures/${locationName}/${main_menu.name}`,
    );
    await uploadBytes(mainMenuRef, main_menu);
    data.picture = await getDownloadURL(mainMenuRef);

    const multiLocations: MultiLocation[] = [];

    for (let index = 0; index < data.multiLocations.length; index++) {
      const banner = banners[index];
      const locationAtIndex = data.multiLocations[index];

      let geoPointsAtIndex: GeoPoint;
      let mapBoxUrl: string | undefined;

      if (
        typeof locationAtIndex.geoLocation === "string" &&
        locationAtIndex.geoLocation !== ""
      ) {
        geoPointsAtIndex = await geocode(
          RequestType.ADDRESS,
          locationAtIndex.geoLocation,
        )
          .then(({ results }) => {
            const { lat, lng } = results[0].geometry.location;
            return new GeoPoint(lat, lng);
          })
          .catch(() => new GeoPoint(0, 0));
      } else {
        geoPointsAtIndex =
          locationAtIndex.geoLocation instanceof GeoPoint
            ? locationAtIndex.geoLocation
            : new GeoPoint(0, 0);
      }

      const bannerUrls: string[] = [];
      for (const file of banner.images) {
        const bannerRef = ref(
          storage,
          `location_pictures/${locationName}/banners/${file.name}`,
        );
        await uploadBytes(bannerRef, file);
        const url = await getDownloadURL(bannerRef);
        bannerUrls.push(url);
      }

      if (geoPointsAtIndex) {
        const { latitude, longitude } = geoPointsAtIndex;
        mapBoxUrl = `https://api.mapbox.com/styles/v1/mapbox/dark-v11/static/pin-l-r+ee8b60(${longitude},${latitude})/${longitude},${latitude},13/1200x100@2x?access_token=${process.env.REACT_APP_MAPBOX_API_KEY}`;
        try {
          const blob = await fetch(mapBoxUrl).then((res) => res.blob());
          const mapRef = ref(
            storage,
            `location_pictures/${locationName}/map_boxes/map_${index + 1}.png`,
          );
          await uploadBytes(mapRef, blob);
          mapBoxUrl = await getDownloadURL(mapRef);
        } catch (error) {
          console.error("Failed to fetch or upload map image", error);
        }
      }

      multiLocations.push({
        city: cities[index] || "",
        geoLocation: geoPointsAtIndex,
        locationName: locationAtIndex.locationName,
        phone: locationAtIndex.phone,
        pictureHash: locationAtIndex.pictureHash,
        pictures: bannerUrls,
        mapBox: mapBoxUrl,
      });
    }

    data.multiLocations = multiLocations;

    data.cities = citiesArray;

    data.pointsRate = Number(data.pointsRate);

    data.rewards = await Promise.all(
      rewards.map(async (file, index) => {
        const rewardRef = ref(
          storage,
          `location_pictures/${locationName}/rewards/${file.image.name}`,
        );
        await uploadBytes(rewardRef, file.image);
        const rewardUrl = await getDownloadURL(rewardRef);
        const rewardAtIndex = data.rewards[index];
        return {
          rewardCost: Number(rewardAtIndex.rewardCost),
          rewardDescription: rewardAtIndex.rewardDescription,
          rewardPicture: rewardUrl,
          rewardTitle: rewardAtIndex.rewardTitle,
          rewardWarning: rewardAtIndex.rewardWarning,
        };
      }),
    );
  }

  data.tags = tags;

  await createLocation(data, locationName, queryClient, user);
};

// UPDATE LOCATION

export const getAddress = async (
  index: number,
  sourceData: Location | undefined,
) => {
  if (sourceData) {
    const geoLocation = sourceData.multiLocations[index].geoLocation;
    if (geoLocation instanceof GeoPoint) {
      const { latitude, longitude } = geoLocation;
      try {
        const { results } = await geocode(
          RequestType.LATLNG,
          `${latitude},${longitude}`,
        );
        return results[0].formatted_address;
      } catch (error) {
        console.error("Failed to get address:", error);
        return "";
      }
    } else {
      return "";
    }
  }
  return "";
};

const handleFileCompression = async (filesArray: File[]) => {
  const options = {
    maxSizeMB: 0.1,
    maxWidthOrHeight: 1280,
    useWebWorker: true,
  };

  try {
    for (let i = 0; i < filesArray.length; i++) {
      filesArray[i] = await imageCompression(filesArray[i], options);
    }
  } catch (error) {
    if (process.env.NODE_ENV === "development") console.error(error);
  }
};

export const onUpdate = async (
  newData: Location,
  sourceData: Location | undefined,
  selectedOptions: CreateLocationOptions,
  removedFiles: RemovedFile[],
  queryClient: QueryClient,
  currentUser: ExtendedUser | undefined,
) => {
  if (!sourceData) {
    toastOptions({
      status: "error",
      position: "top-left",
      message: "Error searching for location data!",
    });
    return;
  }
  if (!currentUser) {
    toastOptions({
      status: "error",
      position: "top-left",
      message: "No user signed in!",
    });
    return;
  }

  const { files, cities } = selectedOptions;
  const { main_menu, banners, rewards } = files;

  let filteredCities: string[] = [];

  if (cities.length > 0) {
    filteredCities = [...new Set(cities)];
  }

  if (_.isEqual(filteredCities, sourceData["cities"]))
    delete (newData as Partial<Location>)["cities"];
  else {
    newData["cities"] = filteredCities;
  }

  for (const [key, value] of Object.entries(newData)) {
    const objKey = key as keyof Location;

    if (objKey === "picture") {
      const removedFile = removedFiles.find(
        (removedFile) => removedFile.removedFile.from === "main_menu",
      );
      if (removedFile && main_menu === null) {
        toastOptions({
          status: "error",
          position: "top-left",
          message: "Upload at least 1 banner image!",
        });
        return;
      } else if (main_menu !== null) {
        if (!removedFile && sourceData[objKey] !== "") {
          toastOptions({
            status: "error",
            position: "top-left",
            message: "You can only have 1 main image!",
          });
          return;
        }
        await handleFileCompression([main_menu]);
        const mainMenuRef = ref(
          storage,
          `location_pictures/${sourceData.docID}/${main_menu.name}`,
        );
        await uploadBytes(mainMenuRef, main_menu);
        newData.picture = await getDownloadURL(mainMenuRef);
        if (!removedFile) {
          const fileRef = getFileRef(
            sourceData,
            sourceData.picture,
            "main_menu",
          );
          await deleteObject(fileRef);
        }
      } else {
        newData.picture = sourceData.picture;
      }
      if (_.isEqual(newData.picture, sourceData[objKey]))
        delete (newData as Partial<Location>)[objKey];
    } else if (objKey === "multiLocations") {
      const auxMultiLocations = await Promise.all(
        newData.multiLocations.map(async (loc, index) => {
          loc = { ...loc, city: cities[index] };
          if (typeof loc.geoLocation === "string") {
            let geoPoint: GeoPoint;
            if (loc.geoLocation !== "") {
              let mapBoxUrl: string | undefined;
              geoPoint = await geocode(RequestType.ADDRESS, loc.geoLocation)
                .then(async ({ results }) => {
                  const { lat, lng } = results[0].geometry.location;
                  mapBoxUrl = `https://api.mapbox.com/styles/v1/mapbox/dark-v11/static/pin-l-r+ee8b60(${lng},${lat})/${lng},${lat},13/1200x100@2x?access_token=${process.env.REACT_APP_MAPBOX_API_KEY}`;

                  await fetch(mapBoxUrl)
                    .then((response) => response.blob())
                    .then(async (blob) => {
                      const fileName = `${sourceData.docID}_map_${index + 1}.png`;
                      const mapRef = ref(
                        storage,
                        `location_pictures/${sourceData.docID}/map_boxes/${fileName}`,
                      );
                      await uploadBytes(mapRef, blob);
                      mapBoxUrl = await getDownloadURL(mapRef);
                    })
                    .catch(() => {
                      throw new Error("Failed to fetch map image");
                    });
                  return new GeoPoint(lat, lng);
                })
                .catch(() => {
                  return new GeoPoint(0, 0);
                });
              loc = { ...loc, geoLocation: geoPoint, mapBox: mapBoxUrl };
            } else {
              geoPoint = new GeoPoint(0, 0);
              loc = { ...loc, geoLocation: geoPoint, mapBox: "" };
            }
          }

          let currentPicturesArray: string[] = [];

          if (sourceData.multiLocations[index])
            currentPicturesArray = sourceData.multiLocations[index].pictures;

          if (currentPicturesArray.length > 0) {
            const removedBannerFiles = removedFiles.filter(
              (removedFile) =>
                removedFile.removedFile.from === "banners" &&
                removedFile.removedFile.index === index,
            );

            currentPicturesArray = currentPicturesArray.filter((picture) => {
              return !removedBannerFiles.some(
                (removedFile) =>
                  removedFile.removedFile.from === "banners" &&
                  removedFile.removedFile.index === index &&
                  picture.includes(removedFile.removedFile.name),
              );
            });
          }

          const uploadedPictures: string[] = [];

          const bannersAtIndex = banners.find(
            (banner) => banner.atIndex === index,
          );

          if (bannersAtIndex) {
            const picturesAtIndex = await Promise.all(
              banners.map(async (files) => {
                await handleFileCompression(files.images);
                return await Promise.all(
                  files.images.map(async (file) => {
                    const fileRef = ref(
                      storage,
                      `location_pictures/${sourceData.docID}/banners/${file.name}`,
                    );
                    await uploadBytes(fileRef, file);
                    return await getDownloadURL(fileRef);
                  }),
                );
              }),
            ).then((result) => result.flat());
            uploadedPictures.push(...picturesAtIndex);
          }

          if (
            currentPicturesArray.length === 0 &&
            uploadedPictures.length === 0
          ) {
            toastOptions({
              status: "error",
              position: "top-left",
              message: "A location needs at least 1 banner image!",
            });
            return { ...loc, pictures: [] };
          }

          const updatedPictures = [
            ...currentPicturesArray,
            ...uploadedPictures,
          ];
          return { ...loc, pictures: updatedPictures };
        }),
      );

      if (auxMultiLocations.some((loc) => loc.pictures.length === 0)) {
        return;
      }

      if (_.isEqual(auxMultiLocations, sourceData[objKey]))
        delete (newData as Partial<Location>)[objKey];
      else newData[objKey] = auxMultiLocations;
    } else if (objKey === "rewards") {
      const updatedRewards = await Promise.all(
        newData.rewards.map(async (reward, index) => {
          const price = Number(reward.rewardCost);
          const removedFile = removedFiles.find(
            (file) =>
              file.removedFile.from === "reward" &&
              file.removedFile.index === index,
          );

          let newPicture: string | FileList = reward.rewardPicture;

          const findReward = rewards.find((reward) => reward.atIndex === index);

          const newFile: File | undefined = findReward?.image;

          if (newFile) {
            if (!removedFile && sourceData.rewards[index]) {
              toastOptions({
                status: "error",
                position: "top-left",
                message: `Reward ${index + 1} can have only 1 picture!`,
              });
              return { ...reward, rewardCost: price, rewardPicture: "" };
            }
            await handleFileCompression([newFile]);
            const rewardRef = ref(
              storage,
              `location_pictures/${sourceData.docID}/rewards/${newFile.name}`,
            );
            await uploadBytes(rewardRef, newFile);
            newPicture = await getDownloadURL(rewardRef);
          } else {
            if (!removedFile && !sourceData.rewards[index].rewardPicture) {
              toastOptions({
                status: "error",
                position: "top-left",
                message: `Reward ${index + 1} must have a picture!`,
              });
              return { ...reward, rewardCost: price, rewardPicture: "" };
            }
          }

          return { ...reward, rewardCost: price, rewardPicture: newPicture };
        }),
      );

      if (updatedRewards.some((reward) => reward.rewardPicture === "")) return;

      if (!_.isEqual(updatedRewards, sourceData[objKey]))
        newData[objKey] = updatedRewards;
      else delete (newData as Partial<Location>)[objKey];
    } else if (objKey === "tags") {
      if (selectedOptions.mainTag === "") {
        toastOptions({
          status: "error",
          position: "top-left",
          message: "Please select a main tag!",
        });
        return;
      }
      if (selectedOptions.additionalTag === "") {
        toastOptions({
          status: "error",
          position: "top-left",
          message: "Please select an additional tag!",
        });
        return;
      }

      const updatedTags = [
        selectedOptions.mainTag,
        selectedOptions.additionalTag,
      ];
      if (_.isEqual(updatedTags, sourceData.tags))
        delete (newData as Partial<Location>)[objKey];
      else newData[objKey] = updatedTags;
    } else if (objKey === "pointsRate") {
      if (!newData.pointsRate) return;
      const pointsRate = Number(newData.pointsRate);
      if (_.isEqual(pointsRate, sourceData.pointsRate))
        delete (newData as Partial<Location>)[objKey];
      else newData[objKey] = pointsRate;
    } else if (value === sourceData[objKey]) delete newData[objKey];
  }

  if (Object.keys(newData).length > 0) {
    try {
      const locationRef = getDocRef(sourceData.docID);
      await updateDoc(locationRef, newData);
      removedFiles.map(async (removedFile) => {
        const fileRef = getFileRef(
          sourceData,
          removedFile.removedFile.deleteFrom.fullPath,
          removedFile.removedFile.from,
        );
        await deleteObject(fileRef);
      });

      if (currentUser.type === "admin") {
        queryClient.setQueryData(
          ["locations"],
          (oldData: Location[] | undefined) => {
            if (!oldData) return oldData;

            return oldData.map((location) =>
              location.docID === newData.docID ? newData : location,
            );
          },
        );
        await queryClient.invalidateQueries({ queryKey: ["locations"] });
      } else if (currentUser.type === "location") {
        queryClient.setQueryData(
          ["userLocation"],
          (oldData: Location | undefined) => {
            if (!oldData) return oldData;
            return { ...oldData, ...newData };
          },
        );
        await queryClient.invalidateQueries({ queryKey: ["userLocation"] });
      }
    } catch {
      return Error("error");
    }
  } else {
    toastOptions({
      status: "warn",
      position: "top-left",
      message: "No changes to update!",
    });
  }
};

// DELETE LOCATION

async function deleteFolderRecursively(folderRef: StorageReference) {
  const listResults = await listAll(folderRef);

  const deleteFilesPromises = listResults.items.map((itemRef) =>
    deleteObject(itemRef),
  );

  const deleteSubfoldersPromises = listResults.prefixes.map((subFolderRef) =>
    deleteFolderRecursively(subFolderRef),
  );

  await Promise.all([...deleteFilesPromises, ...deleteSubfoldersPromises]);
}

export async function deleteLocation(
  selectedLocation: Location | undefined,
  setLoading: Dispatch<SetStateAction<boolean>>,
  queryClient: ReturnType<typeof useQueryClient>,
) {
  setLoading(true);
  if (!selectedLocation) {
    toastOptions({
      status: "error",
      position: "top-left",
      message: "Invalid location data!",
    });
    setLoading(false);
    return;
  }

  const { docID } = selectedLocation;

  try {
    const storage = getStorage();
    const folderRef = ref(storage, `location_pictures/${docID}`);
    await deleteFolderRecursively(folderRef);

    const docRef = doc(db, "locations", docID);
    await deleteDoc(docRef);

    await queryClient.invalidateQueries({ queryKey: ["locations"] });

    toastOptions({
      status: "success",
      position: "top-left",
      message: `Successfully deleted location: ${docID}`,
    });
    setLoading(false);
  } catch {
    toastOptions({
      status: "error",
      position: "top-left",
      message: `Error deleting location: ${docID}`,
    });
  } finally {
    setLoading(false);
  }
}