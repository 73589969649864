import Socials from "./Socials";

export default function ContactUs() {
  return (
    <>
      <div className="mt-7 ipad:mt-0 text-themed-orange flex flex-col">
        <h3 className="text-xl">Contact us:</h3>
        <p className="text-default-white">
          Email:{" "}
          <a href="mailto:support@resturio.com" className="text-themed-orange">
            support@resturio.com
          </a>
        </p>
      </div>
      <Socials />
    </>
  );
}