import React, { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import CustomInput from "./global/Inputs/CustomInput";
import { useModalContext } from "../../../contexts/ModalProvider";
import { updateUserPassword } from "../actions";
import { toastOptions } from "./LocationForms/actions";
import { ToastContainer } from "react-toastify";

interface FormValues {
  password: string;
  confirmPassword: string;
}

export default function LocationModalContents() {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<FormValues>();

  const password = watch("password");
  const { modal, setModal } = useModalContext();
  const [loading, setLoading] = useState<boolean>(false);

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    try {
      await updateUserPassword(data.password);
      setModal(!modal);
      toastOptions({
        status: "success",
        position: "top-left",
        message: "Password updated successfully! 🎉",
      });
      setLoading(!loading);
    } catch (error) {
      console.error(error);
      toastOptions({
        status: "error",
        position: "top-left",
        message: "Failed to update password! 😰",
      });
      setLoading(!loading);
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-11/12 ipad:w-3/4 w-small-laptop:w-1/2 w-desktop:w-[500px] h-auto flex flex-col items-center justify-center p-4 bg-modal rounded shadow-md">
        <h3 className="text-lg font-semibold mb-4 text-admin-purple">
          Create New Password
        </h3>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="w-full flex flex-col gap-4"
        >
          <CustomInput<FormValues>
            placeholder="Something cool, yet extraordinary."
            label="Password"
            type="password"
            fieldName="password"
            register={register}
            options={{
              required: "Password is required",
              minLength: {
                value: 8,
                message: "Password must be at least 8 characters",
              },
            }}
            errors={errors}
            errorMessageName="password"
          />
          <CustomInput<FormValues>
            placeholder="Same as above"
            label="Confirm password"
            type="password"
            fieldName="confirmPassword"
            register={register}
            options={{
              required: "Please confirm your password",
              validate: (value) =>
                value === password || "Passwords do not match",
            }}
            errors={errors}
            errorMessageName="confirmPassword"
          />
          <button
            type="submit"
            className="w-full py-2 bg-admin-purple text-default-white rounded hover:bg-hover-admin-purple"
          >
            {loading ? <span className="admin-loader" /> : "Submit"}
          </button>
        </form>
      </div>
    </>
  );
}