import React, { useContext, useState } from "react";
import { FieldPath, useFieldArray, UseFormReturn } from "react-hook-form";
import { Location } from "../../../../../types";
import FileInput from "./FileInput";
import { useActionsContext } from "../../../../../contexts/ActionsManagerProvider";
import { AuthContext } from "../../../../../contexts/AuthProvider";
import OptionsSelector from "../OptionsSelector";
import { useOptionsContext } from "../../../../../contexts/OptionsProvider";
import { getFileRef } from "../../../../../firebase/actions";
import { useFetchCities } from "../../actions";
import { handleNumberInput } from "./actions";
import { ErrorMessage } from "@hookform/error-message";

interface Props {
  formMethods: UseFormReturn<Location>;
  sourceData?: Location;
}

type FormPath = FieldPath<Location>;
export default function MultipleLocationInputs({
  formMethods,
  sourceData,
}: Props) {
  const [view, setView] = useState<boolean>(false);
  const { register, control, formState } = formMethods;
  const { fields, append, remove } = useFieldArray({
    control,
    name: "multiLocations",
  });
  const context = useActionsContext();
  const { mode } = context.state;
  const { errors } = formState;
  const { currentUser } = useContext(AuthContext);
  const { setRemovedFiles } = useOptionsContext();
  const citiesData = useFetchCities();
  const cities: string[] | undefined = citiesData.data;

  const handleDeleteFiles = (index: number, sourceData?: Location) => {
    if (mode === "UPDATE" && sourceData) {
      const firebaseUrls: string[] | undefined =
        sourceData.multiLocations[index].pictures;

      if (firebaseUrls) {
        firebaseUrls.forEach((firebaseUrl: string) => {
          const fileRef = getFileRef(sourceData, firebaseUrl, "banners");
          const urlArray = fileRef.fullPath.split("/");
          const fileName = urlArray[urlArray.length - 1];
          setRemovedFiles((prev) => [
            ...prev,
            {
              removedFile: {
                deleteFrom: fileRef,
                name: fileName,
                from: "banners",
              },
            },
          ]);
        });
      }
    }
  };

  return (
    <div className="mt-8">
      {fields.map((field, index) => (
        <div
          key={field.id}
          className={`bg-admin-dark-3 rounded-md flex items-center justify-center ${
            index > 0 ? "mt-2" : "mt-4 pb-7"
          }`}
        >
          <div className="w-11/12">
            <h3 className="pt-2 text-default-white">Location {index + 1}</h3>
            <OptionsSelector
              options={cities || []}
              label="Select a city:"
              fieldName={`multiLocations.${index}.city` as FormPath}
              selectionMode="single"
              wrapperClass="mt-4"
              gridClass="grid grid-cols-2 gap-2 mt-2"
              buttonClass="bg-admin-dark"
              index={index}
            />
            <label
              className="mt-4 block text-admin-purple"
              htmlFor={`multiLocations.${index}.geoLocation`}
            >
              Address
              <div className="relative inline-block">
                <img
                  src="/assets/info-icon.png"
                  alt="info icon"
                  className="w-4 h-4 inline-block ml-1 mb-1 cursor-pointer"
                  onClick={() => setView(!view)}
                />
                {view && (
                  <div className="absolute left-0 bottom-full mb-1 w-48 p-2 bg-admin-purple text-default-white text-xs rounded-md shadow-lg">
                    Enter the Google address of the location here.
                  </div>
                )}
              </div>
            </label>
            <input
              {...register(`multiLocations.${index}.geoLocation` as FormPath, {
                required: mode === "UPDATE" ? "This field is required!" : false,
              })}
              type="text"
              placeholder="Google address"
              className="mt-1 w-full bg-transparent placeholder:text-gray-700 text-default-white border-admin-purple focus:outline-none focus:ring-2 focus:ring-admin-purple focus:border-admin-purple invalid:border-red-500 invalid:text-red-500 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
              name={`multiLocations.${index}.geoLocation`}
              id={`multiLocations.${index}.geoLocation`}
            />
            <ErrorMessage
              errors={errors}
              name={`multiLocations.${index}.geoLocation`}
              render={({ message }) => (
                <span className="text-red-500 text-sm mt-1">{message}</span>
              )}
            />
            <label
              className="mt-4 block text-admin-purple"
              htmlFor={`multiLocations.${index}.locationName`}
            >
              Specific location identifier
            </label>
            <input
              {...register(`multiLocations.${index}.locationName` as FormPath, {
                required: mode === "UPDATE" ? "This field is required!" : false,
              })}
              type="text"
              placeholder='e.g: "Victoriei"'
              className="mt-1 w-full bg-transparent placeholder:text-gray-700 text-default-white border-admin-purple focus:outline-none focus:ring-2 focus:ring-admin-purple focus:border-admin-purple invalid:border-red-500 invalid:text-red-500"
              id={`multiLocations.${index}.locationName`}
              name={`multiLocations.${index}.locationName`}
            />
            <ErrorMessage
              errors={errors}
              name={`multiLocations.${index}.locationName`}
              render={({ message }) => (
                <span className="text-red-500 text-sm mt-1">{message}</span>
              )}
            />
            <label
              className="mt-4 block text-admin-purple"
              htmlFor={`multiLocations.${index}.phone`}
            >
              Phone number
            </label>
            <input
              {...register(`multiLocations.${index}.phone` as FormPath, {
                required: mode === "UPDATE" ? "This field is required!" : false,
              })}
              type="text"
              inputMode="numeric"
              placeholder="0712345678"
              className="mt-1 w-full bg-transparent placeholder:text-gray-700 text-default-white border-admin-purple focus:outline-none focus:ring-2 focus:ring-admin-purple focus:border-admin-purple invalid:border-red-500 invalid:text-red-500"
              onKeyDown={() => handleNumberInput}
              name={`multiLocations.${index}.phone`}
              id={`multiLocations.${index}.phone`}
            />
            <ErrorMessage
              errors={errors}
              name={`multiLocations.${index}.phone`}
              render={({ message }) => (
                <span className="text-red-500 text-sm mt-1">{message}</span>
              )}
            />
            {currentUser?.type === "admin" && (
              <>
                <label
                  className="mt-4 block text-admin-purple"
                  htmlFor={`multiLocations.${index}.pictureHash`}
                >
                  Picture Hash Link
                </label>
                <input
                  {...register(
                    `multiLocations.${index}.pictureHash` as FormPath,
                    {
                      required:
                        mode === "UPDATE" ? "This field is required!" : false,
                    },
                  )}
                  type="text"
                  placeholder="e.g. L7EyJRxUyZItQ8NGW"
                  className="mt-1 w-full bg-transparent placeholder:text-gray-700 text-default-white border-admin-purple focus:outline-none focus:ring-2 focus:ring-admin-purple focus:border-admin-purple invalid:border-red-500 invalid:text-red-500"
                  id={`multiLocations.${index}.pictureHash`}
                  name={`multiLocations.${index}.pictureHash`}
                />
                <ErrorMessage
                  errors={errors}
                  name={`multiLocations.${index}.pictureHash`}
                  render={({ message }) => (
                    <span className="text-red-500 text-sm mt-1">{message}</span>
                  )}
                />
              </>
            )}
            <FileInput
              register={register}
              fieldName={`multiLocations.${index}.pictures` as FormPath}
              options={{
                required: mode === "CREATE" ? "This field is required!" : false,
              }}
              label={`${
                mode === "CREATE"
                  ? "Upload banner images"
                  : "Change banner images"
              }`}
              For="banners"
              formState={formState}
              index={index}
              sourceData={sourceData}
              errorMessageName={`multiLocations.${index}.pictures`}
            />

            {index > 0 && (
              <button
                onClick={() => {
                  remove(index);
                  handleDeleteFiles(index, sourceData);
                }}
                className="mb-4 mt-6 bg-red-700 rounded-md p-2 shadow-[0px_0px_10px_-4px_#b91c1c] text-default-white"
              >
                Delete
              </button>
            )}
          </div>
        </div>
      ))}
      <button
        onClick={(e) => {
          e.preventDefault();
          append({
            city: "",
            geoLocation: "",
            locationName: "",
            phone: "",
            pictureHash: "",
            pictures: [],
          });
        }}
        className="flex items-center justify-center border-[1px] border-admin-purple rounded-md p-2 mt-4 "
      >
        <img src="/assets/add-icon.png" alt="Add Icon" className="w-5 h-auto" />
        <p className="text-default-white">Add more locations</p>
      </button>
    </div>
  );
}