import React, { useState } from "react";
import LocationSelector from "../Dropdown/LocationSelector";
import { useLocations } from "../../../../contexts/LocationsProvider";
import { deleteLocation } from "./actions";
import { useQueryClient } from "@tanstack/react-query";
import { ToastContainer } from "react-toastify";

export default function DeleteLocation() {
  const { selectedLocation } = useLocations();
  const [loading, setLoading] = useState(false);
  const queryClient = useQueryClient();

  return (
    <>
      <ToastContainer />
      <h2 className="text-default-white text-xl pt-3">DELETE LOCATION</h2>
      <div className="mt-7 h-auto w-full">
        <LocationSelector />
        <button
          onClick={() =>
            deleteLocation(selectedLocation, setLoading, queryClient)
          }
          className="mb-4 mt-6 bg-red-700 rounded-md p-2 shadow-[0px_0px_10px_-4px_#b91c1c] text-default-white"
        >
          {loading ? <span className="admin-loader" /> : "Delete"}
        </button>
      </div>
    </>
  );
}