import React, { useEffect, useState } from "react";
import { FieldPath, useFieldArray, UseFormReturn } from "react-hook-form";
import { Location } from "../../../../../types";
import FileInput from "./FileInput";
import { useActionsContext } from "../../../../../contexts/ActionsManagerProvider";
import { getFileRef } from "../../../../../firebase/actions";
import { useOptionsContext } from "../../../../../contexts/OptionsProvider";
import { ErrorMessage } from "@hookform/error-message";
import { handleNumberInput } from "./actions";
import Checkbox from "../Checkbox";

interface Props {
  formMethods: UseFormReturn<Location>;
  sourceData?: Location;
}

type FormPath = FieldPath<Location>;

export default function RewardInputs({ formMethods, sourceData }: Props) {
  const { register, control, formState } = formMethods;
  const { fields, append, remove } = useFieldArray({
    control,
    name: "rewards",
  });
  const context = useActionsContext();
  const { mode } = context.state;
  const { errors } = formState;
  const { setRemovedFiles } = useOptionsContext();
  const [isChecked, setIsChecked] = useState<boolean[]>([]);

  useEffect(() => {
    if (!sourceData) return;
    const initialCheckedState = sourceData.rewards.map(
      (reward) => reward.rewardWarning !== "",
    );
    setIsChecked(initialCheckedState);
  }, [sourceData]);

  const handleDeleteFiles = (index: number, sourceData?: Location) => {
    if (mode === "UPDATE" && sourceData) {
      const firebaseUrl: string | undefined =
        sourceData.rewards[index].rewardPicture;

      if (firebaseUrl) {
        const fileRef = getFileRef(sourceData, firebaseUrl, "reward");
        const urlArray = fileRef.fullPath.split("/");
        const fileName = urlArray[urlArray.length - 1];
        setRemovedFiles((prev) => [
          ...prev,
          {
            removedFile: {
              deleteFrom: fileRef,
              name: fileName,
              from: "reward",
            },
          },
        ]);
      }
    }
  };

  return (
    <div className="mt-8">
      {fields.map((field, index) => (
        <div
          key={field.id}
          className={`bg-admin-dark-3 rounded-md flex items-center justify-center ${
            index > 0 ? "mt-2" : "mt-4 pb-7"
          }`}
        >
          <div className="w-11/12">
            <h3 className="pt-2 text-default-white">Reward {index + 1}</h3>
            <label
              className="mt-4 block text-admin-purple"
              htmlFor={`rewards.${index}.rewardTitle`}
            >
              Name
            </label>
            <input
              {...register(`rewards.${index}.rewardTitle` as FormPath, {
                required: mode === "UPDATE" ? "This field is required!" : false,
              })}
              type="text"
              placeholder="Anything you fancy offering."
              className="mt-1 w-full bg-transparent placeholder:text-gray-700 text-default-white border-admin-purple focus:outline-none focus:ring-2 focus:ring-admin-purple focus:border-admin-purple invalid:border-red-500 invalid:text-red-500"
              id={`rewards.${index}.rewardTitle`}
              name={`rewards.${index}.rewardTitle`}
            />
            <ErrorMessage
              errors={errors}
              name={`rewards.${index}.rewardTitle`}
              render={({ message }) => (
                <span className="text-red-500 text-sm mt-1">{message}</span>
              )}
            />
            <label
              className="mt-4 block text-admin-purple"
              htmlFor={`rewards.${index}.rewardCost`}
            >
              Price
            </label>
            <input
              {...register(`rewards.${index}.rewardCost` as FormPath, {
                required: mode === "UPDATE" ? "This field is required!" : false,
                validate: (value: number) => {
                  if (mode === "UPDATE" && value > 0) return true;
                  else if (mode === "UPDATE" && value <= 0)
                    return "Conversion rate must be greater than 0!";
                },
              })}
              type="number"
              inputMode="numeric"
              placeholder="Price of the reward"
              onKeyDown={() => handleNumberInput}
              className="mt-1 w-full bg-transparent placeholder:text-gray-700 text-default-white border-admin-purple focus:outline-none focus:ring-2 focus:ring-admin-purple focus:border-admin-purple invalid:border-red-500 invalid:text-red-500  [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
              id={`rewards.${index}.rewardCost`}
              name={`rewards.${index}.rewardCost`}
            />
            <ErrorMessage
              errors={errors}
              name={`rewards.${index}.rewardCost`}
              render={({ message }) => (
                <span className="text-red-500 text-sm mt-1">{message}</span>
              )}
            />
            <label
              className="mt-4 block text-admin-purple"
              htmlFor={`rewards.${index}.rewardDescription`}
            >
              Description
            </label>
            <textarea
              {...register(`rewards.${index}.rewardDescription` as FormPath, {
                required: mode === "UPDATE" ? "This field is required!" : false,
              })}
              placeholder="Short description"
              className="mt-1 w-full bg-transparent placeholder:text-gray-700 text-default-white border-admin-purple focus:outline-none focus:ring-2 focus:ring-admin-purple focus:border-admin-purple invalid:border-red-500 invalid:text-red-500"
              id={`rewards.${index}.rewardDescription`}
              name={`rewards.${index}.rewardDescription`}
            />
            <ErrorMessage
              errors={errors}
              name={`rewards.${index}.rewardDescription`}
              render={({ message }) => (
                <span className="text-red-500 text-sm mt-1">{message}</span>
              )}
            />
            <div>
              <Checkbox
                isChecked={isChecked}
                setIsChecked={setIsChecked}
                label="Any special rules? (e.g. 18+ / only certain days)"
                identifier={`rewards.${index}.rewardWarning`}
                index={index}
              />

              {isChecked[index] && (
                <>
                  <input
                    {...register(`rewards.${index}.rewardWarning` as FormPath)}
                    type="text"
                    placeholder="e.g. 18+ / only certain days"
                    className="mt-1 w-full bg-transparent placeholder:text-gray-700 text-default-white border-admin-purple focus:outline-none focus:ring-2 focus:ring-admin-purple focus:border-admin-purple invalid:border-red-500 invalid:text-red-500"
                    id={`rewards.${index}.rewardWarning`}
                    name={`rewards.${index}.rewardWarning`}
                  />
                  <ErrorMessage
                    errors={errors}
                    name={`rewards.${index}.rewardWarning`}
                    render={({ message }) => (
                      <span className="text-red-500 text-sm mt-1">
                        {message}
                      </span>
                    )}
                  />
                </>
              )}
            </div>
            <FileInput
              register={register}
              fieldName={`rewards.${index}.rewardPicture` as FormPath}
              options={{
                required: mode === "CREATE" ? "This field is required!" : false,
              }}
              label={`${
                mode === "CREATE"
                  ? "Upload a reward image"
                  : "Change the reward image"
              }`}
              For="reward"
              formState={formState}
              index={index}
              sourceData={sourceData}
              errorMessageName={`rewards.${index}.rewardPicture`}
            />

            {index > 0 && (
              <button
                onClick={() => {
                  remove(index);
                  handleDeleteFiles(index, sourceData);
                }}
                className="mb-4 mt-6 bg-red-700 rounded-md p-2 shadow-[0px_0px_10px_-4px_#b91c1c] text-default-white"
              >
                Delete
              </button>
            )}
          </div>
        </div>
      ))}
      <button
        onClick={(e) => {
          e.preventDefault();
          append({
            rewardTitle: "",
            rewardCost: null,
            rewardDescription: "",
            rewardPicture: "",
            rewardWarning: "",
          });
        }}
        className="flex items-center justify-center border-[1px] border-admin-purple rounded-md p-2 mt-4 "
      >
        <img src="/assets/add-icon.png" alt="Add Icon" className="w-5 h-auto" />
        <p className="text-default-white">Add more rewards</p>
      </button>
    </div>
  );
}