import { useGetLocations } from "../components/Admin/Dashboard/actions";
import { useContext } from "react";
import { AuthContext } from "../contexts/AuthProvider";

const useAdminLocations = () => {
  const { currentUser } = useContext(AuthContext);
  const isAdmin = currentUser?.type === "admin";

  return useGetLocations(isAdmin);
};

export default useAdminLocations;