import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from "react";
import { Location } from "../types";
import useAdminLocations from "../hooks/useAdminLocations";
import useAdminLocationById from "../hooks/useAdminLocationById";

interface LocationsContextInterface {
  locations: Location[] | undefined;
  userLocation: Location | undefined;
  userLoading: boolean;
  adminLoading: boolean;
  adminError: Error | null;
  userError: Error | null;
  selectedLocation: Location | undefined;
  setSelectedLocation: Dispatch<SetStateAction<Location | undefined>>;
}

type Props = {
  children: ReactNode;
};

const LocationsContext = createContext<LocationsContextInterface | undefined>(
  undefined,
);

export const useLocations = () => {
  const context = useContext(LocationsContext);
  if (!context) {
    throw new Error("useLocations must be used within a LocationsProvider");
  }
  return context;
};

export default function LocationsProvider({ children }: Props) {
  const {
    data: locations,
    isLoading: adminLoading,
    error: adminError,
  } = useAdminLocations();
  const {
    data: userLocation,
    isLoading: userLoading,
    error: userError,
  } = useAdminLocationById();
  const [selectedLocation, setSelectedLocation] = useState<
    Location | undefined
  >(undefined);

  const sortedLocations = locations
    ? [...locations].sort((a, b) => a.name.localeCompare(b.name))
    : [];

  if (sortedLocations.length && selectedLocation === undefined)
    setSelectedLocation(sortedLocations[0]);

  return (
    <LocationsContext.Provider
      value={{
        locations,
        userLocation,
        adminLoading,
        userLoading,
        adminError,
        userError,
        selectedLocation,
        setSelectedLocation,
      }}
    >
      {children}
    </LocationsContext.Provider>
  );
}