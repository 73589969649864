import React from "react";

export default function AccountDeletion() {
  return (
    <div className="w-full h-screen text-default-white">
      <h1>Resturio account deletion steps:</h1>
      <p>
        1) Send email to:
        <a href="mailto:support@resturio.com">support@resturio.com</a>
        with the respective account name.
      </p>
      <p>
        2) After receiving the email, the account and all of its data will be
        deleted within 30 days.
      </p>
    </div>
  );
}