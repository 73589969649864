import React from "react";

export default function Socials() {
  return (
    <div className="mt-5 text-themed-orange flex flex-col">
      <h3 className="text-xl">Socials:</h3>
      <a
        href="https://www.linkedin.com/company/105399911"
        className="text-default-white w-fit"
      >
        LinkedIn
      </a>
      <a
        href="https://www.instagram.com/resturio.app/"
        className="text-default-white w-fit"
      >
        Instagram
      </a>
    </div>
  );
}