import { useGetLocationByDocID } from "../components/Admin/Dashboard/actions";
import { useContext } from "react";
import { AuthContext } from "../contexts/AuthProvider";

const useAdminLocationById = () => {
  const { currentUser } = useContext(AuthContext);

  const singleLocation = useGetLocationByDocID(currentUser?.restaurantID ?? "");
  const isLocation = currentUser?.type === "location";
  if (!isLocation || !currentUser?.restaurantID)
    return { data: undefined, isLoading: false, error: null };

  const { data, isLoading, error } = singleLocation;

  return { data, isLoading, error };
};

export default useAdminLocationById;