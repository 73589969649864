import React, { Dispatch, SetStateAction } from "react";

interface BaseProps {
  label: string;
  identifier: string;
}

interface SingleCheckboxProps extends BaseProps {
  isChecked: boolean;
  setIsChecked: Dispatch<SetStateAction<boolean>>;
  index?: never;
}

interface MultiCheckboxProps extends BaseProps {
  isChecked: boolean[];
  setIsChecked: Dispatch<SetStateAction<boolean[]>>;
  index: number;
}

type Props = SingleCheckboxProps | MultiCheckboxProps;

export default function Checkbox({
  isChecked,
  setIsChecked,
  label,
  identifier,
  index,
}: Props) {
  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (typeof isChecked === "boolean") setIsChecked(e.target.checked);
    else if (Array.isArray(isChecked) && typeof index === "number") {
      const updatedCheckedState = [...isChecked];
      updatedCheckedState[index] = e.target.checked;
      setIsChecked(updatedCheckedState);
    }
  };

  return (
    <div className="mt-4 flex flex-row justify-between items-center">
      <label className="block text-admin-purple" htmlFor={identifier}>
        {label}
      </label>
      <input
        type="checkbox"
        name={identifier}
        id={identifier}
        className="ml-2 w-6 h-6 text-admin-purple bg-default-white border-0 rounded-md checked:bg-admin-purple focus:ring-0 focus:outline-none focus:border-0"
        checked={typeof isChecked === "boolean" ? isChecked : isChecked[index]}
        onChange={handleCheckboxChange}
      />
    </div>
  );
}