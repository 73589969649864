import { useForm } from "react-hook-form";
import { Location } from "../../../../types";
import SharedInputs from "../global/Inputs/SharedInputs";
import { useQueryClient } from "@tanstack/react-query";
import React, { useState } from "react";
import { useOptionsContext } from "../../../../contexts/OptionsProvider";
import { onCreate, toastOptions } from "./actions";
import { setKey } from "react-geocode";
import { createFormDefaultValues } from "../../../../data";
import { ToastContainer } from "react-toastify";
import CustomInput from "../global/Inputs/CustomInput";

export default function CreateLocationForm() {
  const methods = useForm<
    Location & { userEmail?: string; userPassword?: string }
  >({
    defaultValues: createFormDefaultValues,
  });
  const {
    reset,
    handleSubmit,
    register,
    formState: { errors },
  } = methods;
  const queryClient = useQueryClient();
  const { selectedOptions, setSelectedOptions } = useOptionsContext();
  const [loading, setLoading] = useState(false);

  setKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? "");

  const handleOnSubmit = async (
    data: Location & { userEmail?: string; userPassword?: string },
  ) => {
    setLoading(true);
    try {
      if (data.userEmail && data.userPassword) {
        const userPayload = {
          display_name: data.name,
          email: data.userEmail,
          password: data.userPassword,
        };
        await onCreate(data, selectedOptions, queryClient, userPayload);
        toastOptions({
          status: "success",
          position: "top-left",
          message: "Location & User created successfully!",
        });
      } else {
        toastOptions({
          status: "error",
          position: "top-left",
          message: "Provide user email and password!",
        });
        return;
      }
      reset(createFormDefaultValues);
      setSelectedOptions({
        cities: [],
        mainTag: "",
        additionalTag: "",
        files: {
          main_menu: null,
          banners: [],
          rewards: [],
        },
      });
      setLoading(false);
    } catch {
      toastOptions({
        status: "error",
        position: "top-left",
        message: "Failed to create location!",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <ToastContainer />
      <h2 className="text-default-white text-xl pt-3">CREATE LOCATION</h2>
      <div className="mt-7 h-auto pb-7 w-full">
        <form onSubmit={handleSubmit(handleOnSubmit)}>
          <SharedInputs formMethods={methods} />
          <h3 className="text-admin-purple mt-5">
            Create a User associated with this location:
          </h3>
          <CustomInput
            type="email"
            placeholder="Enter user email"
            register={register}
            fieldName="userEmail"
            options={{
              required: true,
              pattern: {
                value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                message: "Invalid email address",
              },
            }}
            errors={errors}
            errorMessageName="userEmail"
            label="Email"
            className="mt-1 block w-full p-2 border rounded-md"
          />
          <CustomInput
            type="password"
            placeholder="Password"
            register={register}
            fieldName="userPassword"
            options={{
              required: true,
            }}
            errors={errors}
            errorMessageName="userPassword"
            label="Password"
            className="mt-1 block w-full p-2 border rounded-md"
          />

          <button
            type="submit"
            className="w-full mt-4 py-2 bg-admin-purple cursor-pointer flex justify-center items-center"
            disabled={loading}
          >
            {loading ? <span className="admin-loader" /> : "Create Location"}
          </button>
        </form>
      </div>
    </>
  );
}