import { Helmet } from "react-helmet-async";
import { isRouteErrorResponse, useRouteError } from "react-router-dom";

export default function Error() {
  const error = useRouteError();
  let errorMessage: string;

  if (isRouteErrorResponse(error)) {
    errorMessage = error.data.message || error.statusText;
  } else if (typeof error === "string") {
    errorMessage = error;
  } else {
    errorMessage = "Unknown error";
  }

  return (
    <>
      <Helmet>
        <title>Resturio: {errorMessage}</title>
      </Helmet>
      <div className="w-screen h-screen flex items-center justify-center">
        <div className="flex flex-col text-center w-4/5 items-center justify-center text-sm md:text-md w-desktop-md:text-l">
          <h1 className="text-themed-orange text-5xl pb-5">Oops!</h1>
          <p className="text-default-white">
            Sorry, an unexpected error has occurred.
          </p>
          <p className="text-themed-orange">
            Error:
            <i> {errorMessage}</i>
          </p>
          <p className="text-default-white">
            The page you were looking for might've not been found, but hey, you
            can still download our app here:
          </p>
          <div className="flex flex-col justify-center w-full mx-auto mt-5 items-center ipad:flex-row ipad:justify-between ipad:w-2/3 md:w-1/2 md:text-md w-small-laptop:w-1/3 w-desktop-md:w-1/4">
            <a
              href="https://apps.apple.com/us/app/resturio/id6739811265"
              className="w-fit"
            >
              <button className="flex items-center justify-center mb-3 text-md w-40 text-dark bg-default-white text-center p-2 rounded-xl opacity-80 ipad:mb-0">
                <p>App Store</p>
                <img
                  className="inline-block h-[18px] w-auto -translate-y-[1px] translate-x-1 md:h-[22px] md:-translate-y-[2px]"
                  src="/assets/apple2.webp"
                  alt="apple logo"
                />
              </button>
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.resturo.resturio"
              className="w-fit"
            >
              <button className="flex items-center justify-center text-md w-40 text-dark bg-default-white text-center p-2 rounded-xl opacity-80">
                Google Play
                <img
                  className="inline-block h-[18px] w-auto translate-y-[1px] translate-x-1 md:h-[22px]"
                  src="/assets/playstore2.webp"
                  alt="playstore logo"
                />
              </button>
            </a>
          </div>
          <a href="/" className="text-themed-orange pt-5">
            Take me back home
          </a>
        </div>
      </div>
    </>
  );
}