import { CreateLocationInputs } from "../types";

export const SEOdata = {
  title: "Resturio Loyality App for Restaurants, Coffee Shops and Bars",
  description:
    "Resturio is a loyalty rewards app that enhances customer experiences at restaurants, cafes, and bars with personalized rewards through virtual wallets.",
  keywords: [
    "resturio",
    "exclusive rewards",
    "loyalty rewards app",
    "earn and redeem points for special benefits",
    "explore places",
    "unlock exclusive offers",
    "exclusive rewards at restaurants",
  ],
  faviconUrl: "https://resturio.com/assets/favicon.ico",
  imageUrl: "https://resturio.com/assets/logo.webp",
  url: "https://resturio.com/",
  jsonLd: {
    "@context": "https://schema.org",
    "@type": "SoftwareApplication",
    additionalType: "https://schema.org/MobileApplication",
    name: "Resturio",
    url: "https://resturio.com/",
    image: "https://resturio.com/assets/logo.webp",
    description:
      "Resturio is a new loyalty rewards app that improves customer experiences at restaurants, coffee shops, and bars. It offers a personalized rewards system through virtual wallets, where customers can earn and redeem points for special benefits.",
    applicationCategory: "BusinessApplication",
    operatingSystem: "Web",
    softwareVersion: "1.0",
    // downloadUrl: [
    //   "https://apps.apple.com/app/idAPP_ID",
    //   "https://play.google.com/store/apps/details?id=APP_ID",
    // ], when the time is right
    // sameAs: [
    //   "https://www.facebook.com/Resturio",
    //   "https://twitter.com/Resturio",
    //   "https://www.instagram.com/Resturio",
    // ], if necessary
  },
};

export const navLinksData = [
  {
    id: 1,
    className: "nav",
    to: "#showcase",
    text: "The concept",
  },
  {
    id: 2,
    className: "nav",
    to: "#about-us",
    text: "About us",
  },
];

export const heroContents = {
  header: "Resturio",
  spans: ["Unlock rewards ", "Download now "],
  paragraphs: ["at your favorite:", "to enhance your dining experience!"],
  listItems: [
    {
      id: 1,
      icon: {
        src: "/assets/fork-and-knife.png",
        alt: "Cutlery",
      },
      text: "Restaurants",
    },
    {
      id: 2,
      icon: {
        src: "/assets/cocktail-glass.png",
        alt: "Cocktails",
      },
      text: "Bars",
    },
    {
      id: 3,
      icon: {
        src: "/assets/coffee.png",
        alt: "Coffee",
      },
      text: "Cafés",
    },
  ],
};

export const showcaseData = [
  {
    heading: "places.",
    span: "Explore",
    paragraph:
      "Every outing is an adventure, blending familiar spots with hidden gems. Rediscover favorites and find new experiences, guided by technology. Turn each trip into an exciting journey.",
    imgSrc: "/assets/screen_shots/explore.webp",
    imgAlt: "Explore",
  },
  {
    heading: "points.",
    span: "Earn",
    paragraph:
      "Earn rewards effortlessly by scanning a QR code after purchases. Earn points equal to your spending, and redeem them for discounts, offers, or free items. Turn every outing into a chance to save and enjoy more.",
    imgSrc: "/assets/screen_shots/qr.webp",
    imgAlt: "QRCode",
  },
  {
    heading: "up.",
    span: "Level",
    paragraph:
      "Earn experience points with every purchase at your favorite spots. Each point levels up your rewards, unlocking exclusive offers and personalized perks. Enjoy more savings and benefits with every outing, one point at a time.",
    imgSrc: "/assets/screen_shots/points.webp",
    imgAlt: "Points",
  },
  {
    heading: "rewards",
    span: "Redeem",
    paragraph:
      "Enjoy any of the carefully selected rewards for free! Once you've accumulated enough points, you can redeem a reward anytime.",
    imgSrc: "/assets/screen_shots/redeem.webp",
    imgAlt: "redeem",
  },
  {
    heading: "tell your friends!",
    span: "And",
    paragraph:
      "Visit your profile page to track your progress and achievements. Monitor levels, view accumulated points, and see upcoming rewards. Your profile showcases every milestone and perk, helping you maximize the benefits of each outing.",
    imgSrc: "/assets/screen_shots/profile.webp",
    imgAlt: "Profile",
  },
];

export const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 2,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

export const aboutUsData = [
  {
    id: 1,
    paragraph:
      "Resturio is a new loyalty rewards app that improves the customer experience at restaurants, coffee shops and bars.",
  },
  {
    id: 2,
    paragraph:
      "It offers a personalized benefits system through virtual wallets, where customers can earn and redeem points for special rewards.",
  },
  {
    id: 3,
    paragraph:
      "Resturio makes managing loyalty programs easy for businesses, providing a smooth and engaging rewards experience for their customers.",
  },
  {
    id: 4,
    paragraph:
      "Discover new places or return to your favorite spots! We partnered up with 18 locations across Romania!",
  },
  {
    id: 5,
    paragraph:
      "Earn back after each visit! For each 100 RON spent, get back an average of 10 RON by using our rewards system.",
  },
  {
    id: 6,
    paragraph:
      "Finally, get rewarded! Spend your points on 100+ Resturio exclusive rewards across your favorite places!",
  },
];

// ======================
// ADMIN
// ======================

export const cities: string[] = [
  "Bucuresti",
  "Cluj",
  "Timisoara",
  "Brasov",
  "Iasi",
  "Oradea",
  "Constanta",
];

export const main_tags: string[] = [
  "Mexican",
  "Oriental",
  "Cafe",
  "International",
  "French",
  "Italian",
  "Asian",
  "Bakery",
  "Tea House",
  "Bar",
  "American",
  "Korean",
  "Indian",
  "Mediterranean",
  "Greek",
  "Spanish",
  "Fusion",
];

export const additional_tags: string[] = [
  "Hidden Gem",
  "Shisha",
  "Views",
  "Burger Bar",
  "Dessert",
  "Wine",
  "Sushi",
  "Rooftop",
  "Single Origin",
  "Craft Beer",
  "Artisan Pizza",
  "Steakhouse",
  "Sports Bar",
  "Karaoke",
  "Comedy Nights",
  "Outdoors",
  "Cozy",
  "Late Night",
];

export const createFormDefaultValues: CreateLocationInputs = {
  name: "",
  description: "",
  cities: [],
  picture: "",
  pictureHash: "",
  pointsRate: null,
  multiLocations: [
    {
      city: "",
      geoLocation: "",
      locationName: "",
      phone: "",
      pictureHash: "",
      pictures: [],
    },
  ],
  rewards: [
    {
      rewardCost: null,
      rewardDescription: "",
      rewardPicture: "",
      rewardTitle: "",
      rewardWarning: "",
    },
  ],
  tags: [],
};