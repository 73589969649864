import { useForm } from "react-hook-form";
import { Location } from "../../../../types";
import SharedInputs from "../global/Inputs/SharedInputs";
import LocationSelector from "../Dropdown/LocationSelector";
import { useLocations } from "../../../../contexts/LocationsProvider";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useOptionsContext } from "../../../../contexts/OptionsProvider";
import { getAddress, onUpdate, toastOptions } from "./actions";
import { AuthContext } from "../../../../contexts/AuthProvider";
import { setKey } from "react-geocode";
import { useQueryClient } from "@tanstack/react-query";
import { ToastContainer } from "react-toastify";
import useSourceData from "../../../../hooks/useSourceData";

export default function UpdateLocationForm() {
  const formDefaultsRef = useRef<Location>({} as Location);
  const methods = useForm<Location>({
    defaultValues: formDefaultsRef.current,
  });
  const { reset, setValue, handleSubmit, watch } = methods;
  const newData = watch();
  const queryClient = useQueryClient();
  setKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? "");
  const [loading, setLoading] = useState(false);
  const { currentUser } = useContext(AuthContext);
  const { selectedLocation, userLocation } = useLocations();
  const { selectedOptions, setSelectedOptions, removedFiles, setRemovedFiles } =
    useOptionsContext();

  const sourceData = useSourceData(currentUser, userLocation, selectedLocation);

  const handleOnSubmit = async () => {
    setLoading(true);
    try {
      await onUpdate(
        newData,
        sourceData,
        selectedOptions,
        removedFiles,
        queryClient,
        currentUser,
      );
      toastOptions({
        status: "success",
        position: "top-left",
        message: "Location updated successfully! 🎉",
      });
      setRemovedFiles([]);
      setLoading(false);
    } catch (error) {
      toastOptions({
        status: "error",
        position: "top-left",
        message: "Failed to update location! 😰",
      });
      setRemovedFiles([]);
      if (process.env.NODE_ENV === "development") console.error(error);
      setLoading(false);
    }
  };

  const setFormDefaults = (data: Location) => {
    return {
      pictureHash: data.pictureHash,
      name: data.name,
      description: data.description,
      pointsRate: data.pointsRate,
      multiLocations: data.multiLocations.map((loc) => ({
        city: loc.city,
        geoLocation: "",
        locationName: loc.locationName,
        phone: loc.phone,
        pictures: loc.pictures,
        pictureHash: loc.pictureHash,
      })),
      picture: data.picture,
      rewards: data.rewards,
      tags: data.tags,
    };
  };

  useEffect(() => {
    if (sourceData) {
      const defaults = setFormDefaults(sourceData);

      const cities = defaults.multiLocations.map((loc) => loc.city);
      reset(defaults);
      setSelectedOptions({
        cities,
        mainTag: defaults.tags[0] || "",
        additionalTag: defaults.tags[1] || "",
        files: {
          main_menu: null,
          banners: [],
          rewards: [],
        },
      });

      defaults.multiLocations.forEach(async (_, index) => {
        const address: string = (await getAddress(index, sourceData)) || "";
        setValue(`multiLocations.${index}.geoLocation`, address);
      });
    }
  }, [reset, setValue, setSelectedOptions, sourceData]);

  return (
    <>
      <ToastContainer />
      <h2 className="text-default-white text-xl pt-3">UPDATE LOCATION</h2>
      <div className="mt-7 h-auto w-full">
        {currentUser?.type === "admin" && <LocationSelector />}
        <form onSubmit={handleSubmit(handleOnSubmit)}>
          <SharedInputs formMethods={methods} sourceData={sourceData} />
          <button
            type="submit"
            className="w-full mt-4 py-2 bg-admin-purple cursor-pointer flex justify-center items-center mb-10"
            disabled={loading}
          >
            {loading ? <span className="admin-loader" /> : "Update Location"}
          </button>
        </form>
      </div>
    </>
  );
}