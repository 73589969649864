import { UseFormReturn } from "react-hook-form";
import { Location } from "../../../../../types";
import CustomInput from "./CustomInput";
import OptionsSelector from "../OptionsSelector";
import { additional_tags, main_tags } from "../../../../../data";
import FileInput from "./FileInput";
import RewardInputs from "./RewardInputs";
import MultipleLocationInputs from "./MultipleLocationInputs";
import { useActionsContext } from "../../../../../contexts/ActionsManagerProvider";
import React, { useContext } from "react";
import { AuthContext } from "../../../../../contexts/AuthProvider";
import { ErrorMessage } from "@hookform/error-message";

interface Props {
  formMethods: UseFormReturn<Location>;
  sourceData?: Location;
}

export default function SharedInputs({ formMethods, sourceData }: Props) {
  const { register, formState } = formMethods;
  const context = useActionsContext();
  const { mode } = context.state;
  const { currentUser } = useContext(AuthContext);
  const { errors } = formState;
  return (
    <>
      {currentUser?.type === "admin" && (
        <CustomInput<Location>
          register={register}
          fieldName="pictureHash"
          type="text"
          placeholder="e.g. L7EyJRxUyZItQ8NGW"
          options={{
            required: mode === "UPDATE" ? "This field is required!" : false,
          }}
          className="mt-1"
          errors={errors}
          errorMessageName="pictureHash"
          label="Picture Hash Link"
        />
      )}
      <CustomInput<Location>
        register={register}
        fieldName="name"
        type="text"
        placeholder="People know you by this name"
        options={{
          required: mode === "CREATE" ? "This field is required!" : false,
        }}
        className="mt-1"
        errors={errors}
        errorMessageName="name"
        label="Location name"
      />
      <label className="mt-4 block text-admin-purple" htmlFor="description">
        Description
      </label>
      <textarea
        {...register("description", {
          required: mode === "UPDATE" ? "This field is required!" : false,
        })}
        placeholder="Short description"
        className="mt-1 w-full bg-transparent placeholder:text-gray-700 text-default-white border-admin-purple focus:outline-none focus:ring-2 focus:ring-admin-purple focus:border-admin-purple invalid:border-red-500 invalid:text-red-500"
        rows={3}
        id="description"
        name="description"
      />
      <ErrorMessage
        errors={errors}
        name="description"
        render={({ message }) => (
          <span className="text-red-500 text-sm mt-1">{message}</span>
        )}
      />
      <CustomInput<Location>
        register={register}
        fieldName="pointsRate"
        type="number"
        placeholder='e.g: Type "10" for 10% return'
        options={{
          required: mode === "UPDATE" ? "This field is required!" : false,
          validate: (value: number) => {
            if (mode === "UPDATE" && value > 0) return true;
            else if (mode === "UPDATE" && value <= 0)
              return "Conversion rate must be greater than 0!";
          },
        }}
        inputMode="numeric"
        onKeyDown={(e) => {
          const isNumber = /[0-9]/.test(e.key);
          const isAllowedKey = ["Backspace", "Control", "Meta"].includes(e.key);

          if (
            !isNumber &&
            !isAllowedKey &&
            !((e.ctrlKey || e.metaKey) && e.key === "v") &&
            !((e.ctrlKey || e.metaKey) && e.key === "c")
          )
            e.preventDefault();
        }}
        className="mt-1"
        errors={errors}
        errorMessageName="pointsRate"
        label="Conversion Rate"
      />
      <MultipleLocationInputs
        formMethods={formMethods}
        sourceData={sourceData}
      />
      <FileInput
        register={register}
        fieldName="picture"
        options={{
          required: mode === "CREATE" ? "This field is required!" : false,
        }}
        label={`${
          mode === "CREATE" ? "Upload the main image" : "Change the main image"
        }`}
        For="main_menu"
        formState={formState}
        sourceData={sourceData}
        errorMessageName="picture"
      />

      <RewardInputs formMethods={formMethods} sourceData={sourceData} />
      <OptionsSelector
        options={main_tags}
        label="Select a cusine tag:"
        fieldName="mainTag"
        selectionMode="single"
        wrapperClass="mt-4"
        gridClass="grid grid-cols-2 gap-2 mt-2"
        buttonClass="bg-admin-dark-3"
      />
      <OptionsSelector
        options={additional_tags}
        label="Select an additional tag:"
        fieldName="additionalTag"
        selectionMode="single"
        wrapperClass="mt-4"
        gridClass="grid grid-cols-2 gap-2 mt-2"
        buttonClass="bg-admin-dark-3"
      />
    </>
  );
}