import ContactUs from "./ContactUs";
import Form from "./Form";
import OtherLinks from "./OtherLinks";

export default function Footer() {
  return (
    <section className="relative z-10 w-screen h-auto bg-dark-orange flex flex-col items-center px-9 ipad:px-20 ipad:pt-10 w-small-laptop:px-44  w-small-laptop:items-start w-desktop:px-56 w-desktop-lg:px-96">
      <div className="grid grid-cols-1 grid-rows-1 gap-5 items-start self-start pt-5 ipad:grid-cols-2 w-small-laptop:grid-cols-3">
        <div className="flex flex-col">
          <h2 className="text-xl text-themed-orange text-center ipad:text-left mb-2">
            Do you own a restaurant, bar or café?
          </h2>
          <Form />
        </div>
        <div className="flex flex-col">
          <ContactUs />
        </div>
      </div>
      <OtherLinks />
    </section>
  );
}