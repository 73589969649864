import React from "react";

export default function OtherLinks() {
  const year = new Date().getFullYear();
  return (
    <div className="flex flex-col w-full text-sm text-gray-700 bottom-5 gap-2 mt-28 mb-5 ipad:flex-row ipad:justify-center ipad:gap-4">
      <a href="/">&#169; {year} Resturo Labs S.R.L</a>
      <a href="/">Terms and Conditions</a>
      <a href="/">Privacy</a>
    </div>
  );
}